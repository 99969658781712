name: Johann Misterio
prof_suffix:
  - MBA
  - PMP
headline: Web/Digital Strategy and Solutions
region: New York Metropolitan Area
email: j8nn3str@proton.me
website: 
  url: https://profile.j8nn3str.me
  text: profile.j8nn3str.me
socials:
  - platform: LinkedIn
    url: https://www.linkedin.com/in/j8nn3str
    username: j8nn3str
  - platform: GitHub
    url: https://github.com/j8nn3str
    username: j8nn3str
summary:
  - overview: "Versatile digital marketing professional specializing in web/digital strategy and solutions, with a strong foundation in engineering, business administration, and information technology. Proven track record of seamlessly integrating technical expertise across diverse disciplines with business acumen to drive success."

  - subjects:
    - subject: Solutions Development
      description: Implemented innovative and systematic solutions to complex challenges in web and digital strategy, such as the development and design of an otherwise manual business process built around Robotic Process Automation (RPA).
      icon: flask

    - subject: System Administration
      description: Adept at administering a wide array of systems, including but not limited to various business productivity tools, web development platforms, enterprise desktop governance systems, and Enterprise Resource Planning (ERP) systems. Proficient in navigating and optimizing diverse technological landscapes to ensure seamless functionality and operational efficiency.
      icon: wrench

    - subject: Operations Management
      description: Initiated and structured a successful web development operation in a previous role from staff management to formalizing a range of services. Currently leading the transformation of the current team to harness their latent web design and development talents.
      icon: gears


    - subject: Leadership and Strategy
      description: Developed business processes documents and drafted policies to ensure operational continuity. Regularly invited to participate in steering committees within the organization, contributing valuable insights and innovative solutions to shape technology strategies.
      icon: chess-king

education:
  - institution: Rutgers Business School
    location: Piscataway, New Jersey 08854
    degree: Master of Business Administration
    details: "Area of Study: Supply Chain Management - Enterprise Resource Planning (ERP) systems"
    start_date: 2015-09-01
    end_date: 2019-01-01

  - institution: Rutgers School of Engineering
    location: Piscataway, New Jersey 08854
    degree: Bachelor of Science, Chemical Engineering
    details: Magna Cum Laude
    start_date: 2009-09-01
    end_date: 2012-05-01

  - institution: Hudson County Community College
    location: Jersey City, New Jersey 07306
    degree: Associate of Science, Engineering Science
    details: Magna Cum Laude
    start_date: 2007-09-01
    end_date: 2009-05-31

experience:  
  - company: NewYork-Presbyterian Hospital
    type: Full-time
    location: New York, New York 10017
    title: Manager, Marketing Web and Technical Services
    description: 
    duties: 
      - Oversee the entire web design, web development, and web content production operations, ensuring strategic alignment. Engage various roles within said operations by providing technical expertise and leadership to effectively meet objectives.
      - Take ownership of and manage a suite of crucial web tools/platforms for the marketing department, ensuring seamless functionality and optimal utilization. Acknowledged as the primary point of contact for these tools, providing expert guidance and support to colleagues for effective utilization and troubleshooting.
      - Develop and manage support procedures, tools, and documentation, including drafting scope documents, and policies.
      - Develop solutions and troubleshoot business challenges, often responding to technical inquiries. Leverage a deep understanding of everyday tools, including unconventional solutions within tools to address issues innovatively. Solutions include but are not limited to scripts, static web applications, automation, etc.
      - Manage technical projects, serving as the sole subject matter expert for all technical aspects. Collaborate seamlessly with internal and external technical partners, ensuring effective coordination and communication throughout project lifecycles.
    start_date: 2022-04-25
    end_date: "Present"
    skills:
      - Drupal
      - Jekyll
      - PowerQuery
      - PowerApps
      - PowerAutomate
      - SharePoint
      - Workfront
      - Smartsheet

  - company: Rutgers University, Office of the Chancellor - IT Services
    type: Full-time
    location: Piscataway, New Jersey 08854
    title: Unit Computing Manager, Web Services
    description: I simultaneously lead and operate as a part of a team of web development/design specialists to provide users (1) a highly customized digital/web presence, (2) digital/web-based solutions, and (3) planning and infrastructure to make all of the above possible.
    duties:
      - Designed plans for implementing industry trends and technologies in DevOps to the web team, and to the rest of organization.
      - Drafted business policies and processes to keep up with technology adoption.
      - Maintained and improved the server infrastructure responsible for hosting over one-hundred (100) sites and applications across different environments.
      - Managed and coordinated web development projects to produce solutions and facilitate appropriate communications and resource-sharing between the departments and various stakeholders in senior leadership.
      - Developed tools for a more efficient web development experience for the web team and the rest of the organization.
      - Developed custom solutions using Microsoft Power Platform suite of software such as PowerApps, PowerAutomate, and SharePoint.
    start_date: 2016-08-22
    end_date: 2022-08-22
    skills:
      - Drupal
      - Jenkins
      - Docker
      - PowerApps
      - PowerAutomate
      - SharePoint
      - Windows Server OS
      - Agile

  - company: Rutgers University, Undergraduate Affairs & Student Affairs
    type: Full-time
    title: Unit Computing Specialist
    location: New Brunswick, New Jersey 08901
    description: I was tasked with operational continuity of the IT technical support operation, performing tasks across multiple support levels.
    duties:
      - Provided end-user support, remote and on-site installation, and maintenance of computing environments, software, hardware, and related technologies and tools.
      - Performed periodic software updates and hardware upgrades.
      - Processed software and hardware technology procurement, purchasing, and installation on behalf of customers.
      - Maintained documentation of systems, procedures, and networks.
      - Hired, trained, and managed student employees who operate the IT Support Desk.
      - Took product ownership of special interest IT projects such as the management of SharePoint intranets and solutions.
    start_date: 2012-05-10
    end_date: 2016-08-22
    skills:
      - Windows OS
      - Mac OS
      - PowerShell
      - Technical Staff Management
      - Customer Service
      - Customer Management
      - SharePoint

  - company: Optics For Research Inc.
    type: Part-time
    title: Assistant to the Quality Control Engineer
    location: Verona, New Jersey 07044
    description:
    duties:
      - Worked with customers to coordinate Return Merchandise Authorization (RMA) procedures.
      - Coordinated with in-house technicians, engineers, and other staff to process returned merchandise in a timely manner.
      - Interfaced with an Enterprise Resource Planning (ERP) system to log transactions for the whole process.
    start_date: 2007-08-15
    end_date: 2009-08-07
    skills:
      - Microsoft Dynamics
      - Customer Service
      - Customer Management

certifications:
  - name: Project Management Professional (PMP)®
    authority: Project Management Institute
    issue_date: 2020-10-04
    cred_url: "https://www.youracclaim.com/badges/0439c2c7-d889-4cb2-b47b-6267aed1be0f"

  - name: SAP Certified Associate
    authority: SAP
    issue_date: 2017-09-09
    cred_url: "https://www.youracclaim.com/badges/1966ca40-a5dc-492c-8ca9-de95e09615fb"

  - name: ITIL® Foundation CPD
    authority: AXELOS
    issue_date: 2020-10-05
    cred_url: "https://www.youracclaim.com/badges/18375f75-5fa1-4b30-86bf-05cdcedb00a5"