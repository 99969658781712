var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const resumeData = require('../../../_data/resume.yml')
const skillsData = require('../../../_data/skills.yml')
const siteData = require('../../../_data/site.json')

import styles from '../../css/base/_bootstrap_overrides.module.scss'

const $ = require('jquery')
const dayjs = require('dayjs')

var docfilename = siteData.docfilename + "-"+ dayjs().format('YYYYMMDDHHmmss');

var formatDate = function (rawdate) {
	if (dayjs(rawdate).isValid()) {
		return dayjs(rawdate).format('MMM YYYY')
	}
	else {
		return "Present"
	}
}

var sectionLine = function (length) {

	let lengthvalue

	if (length) {
		lengthvalue = length
	}
	else {
		lengthvalue = 100
	}

	return {
		canvas: [{
			type: 'line',
			x1: 0, y1: 0, x2: lengthvalue, y2: 0,
			solid: { length: 1 },
			lineWidth: 1,
			color: [ styles.header1Style]
		}]
	}
}

var lineBreak = function (lineheight) {
	return {
		text: '\n',
		lineHeight: lineheight
	}
}

var highlights = []
for (var i in resumeData.summary[1].subjects) {
	highlights.push(
		[resumeData.summary[1].subjects[i].description]
	)
}

var experiences = []
for (var i in resumeData.experience) {
	var experience_duties = []

	for (var j in resumeData.experience[i].duties) {
		experience_duties.push(
			{ text: resumeData.experience[i].duties[j] }
		)
	}

	experiences.push(
		[
			{ text: resumeData.experience[i].title, style: ['header3'] },
			{
				text: [
					{
						text: [
							{ style: ['bold', 'detail'], text: formatDate(resumeData.experience[i].start_date) },
							{ style: ['bold', 'detail'], text: " - " },
							{ style: ['bold', 'detail'], text: formatDate(resumeData.experience[i].end_date) },
							{ style: ['detail'], text: " | " }
						]

					},
					resumeData.experience[i].company
				],
				style: ['detail']
			},
			lineBreak(0.5),
			resumeData.experience[i].description,
			lineBreak(0.5),
			{ ul: experience_duties },
			lineBreak(0.5),
		]
	)
}

var education = []
for (var i in resumeData.education) {
	education.push(
		lineBreak(0.5),
		{ text: resumeData.education[i].degree, style: ['header3',] },
		{
			text: [
				{
					text: [
						{ style: ['bold', 'detail'], text: formatDate(resumeData.education[i].start_date) },
						{ style: ['bold', 'detail'], text: " - " },
						{ style: ['bold', 'detail'], text: formatDate(resumeData.education[i].end_date) },
						{ style: ['detail'], text: " | " }
					]

				},
				resumeData.education[i].institution
			],
			style: ['detail']
		}
	)
}

var certifications = []
for (var i in resumeData.certifications) {
	certifications.push(
		lineBreak(0.5),
		{ text: resumeData.certifications[i].name, style: ['header3',] },
		{
			text: [
				{
					text: [
						{ style: ['bold', 'detail'], text: formatDate(resumeData.certifications[i].issue_date) },
						{ style: ['detail'], text: " | " }
					]

				},
				resumeData.certifications[i].authority
			],
			style: ['detail']
		}
	)
}

var socials = []
var socialsLength = resumeData.socials.length;

for (var i in resumeData.socials) {
	if (i == 0) {

		var col_width = "auto"
	}
	else {
	}

	if (i == (socialsLength - 1)) {
		var delimiter = "  "
	}
	else {
		var delimiter = " |"
	}
	socials.push(
		{
			width: col_width,
			style: ['subdetail'],
			text: [
				{ style: ['bold', 'subdetail'], text: resumeData.socials[i].platform },
				": ",
				{
					text: resumeData.socials[i].username,
					link: resumeData.socials[i].url,
					style: ['subdetail', 'link']

				},
				{ style: ['subdetial'], text: delimiter }
			]
		}
	)
}

socials.push({
    width: "auto", // or specify the width accordingly
    style: ['subdetail'],
    text: [
		{ style: ['subdetail'], text: " | " },
        { style: ['bold', 'subdetail'], text: 'Website' },
        ": ",
        {
            text: resumeData.website.text,
            link: resumeData.website.url,
            style: ['subdetail', 'link']
        },

    ]
});
var social_columns = {
	columns: socials,
	columnGap: 2
}

var skills = []
let groupedData = {};
skillsData.forEach(item => {
	if (!groupedData[item.category]) {
		groupedData[item.category] = {};
	}
	if (!groupedData[item.category][item.areas]) {
		groupedData[item.category][item.areas] = [];
	}
	groupedData[item.category][item.areas].push(item.name);
});

for (let category in groupedData) {
	skills.push({ style: ['header3'], text: category });
	for (let area in groupedData[category]) {
		skills.push(
			{
				style: ['bold', 'subdetail'], text: [area, ": "]
			}, { style: ['subdetail'], text: groupedData[category][area].join(', ') },
			lineBreak(0.5)
		);
	}
}

var dd = {
	content: [
		{ text: resumeData.name.toUpperCase(), style: ['title'] },      //Title
		{ text: resumeData.headline, style: ['headline'] },
		lineBreak(0.5),
		// Contact
		{ text: [resumeData.region," | ", {text: resumeData.email, link: resumeData.email, style:"link"}], style: ['subdetail'] },
		// Socials
		social_columns,
		// Summary
		lineBreak(0.5),
		{ text: "Summary", style: ['header1'] },
		sectionLine(500),
		lineBreak(0.5),
		{ text: resumeData.summary[0].overview, lineHeight: 1.25 },
		// Highlights
		lineBreak(0.2),

		{ text: 'Highlights', style: ['header2'] },
		{ ul: highlights },
		// Experience
		lineBreak(0.5),
		{ text: 'Experience', style: ['header1'] },
		sectionLine(500),
		lineBreak(0.5),
		experiences,
		// Education and Certifications
		{
			columns: [
				[
					{ text: 'Education', style: ['header1'] },
					sectionLine(275),
					education
				],
				[
					{ text: 'Certifications', style: ['header1'] },
					sectionLine(225),
					certifications
				],
			],
			columnGap: 10
		},
		// Skills,
		lineBreak(0.5),
		{ text: 'Skills', style: ['header1'] },
		sectionLine(500),
		lineBreak(0.5),
		skills
	],
	styles: {
		title: {
			fontSize: 22,
			margin: [1, 0, 0, 0],
			color: styles.primary
		},
		header1: {
			fontSize: 14,
			lineHeight: 1,
			margin: [1, 0, 0, 0],
			color: styles.header1Style
		},
		header2: {
			fontSize: 13,
			color: styles.header2Style
		},
		header3: {
			fontSize: 11,
			color: styles.header3Style
		},
		headline: {
			lineHeight: 1
		},
		detail: {
			fontSize: 10
		},
		subdetail: {
			fontSize: 9,
		},
		subsubdetail: {
			fontSize: 8,
		},
		bold: {
			bold: true
		},
		link: {
			decoration: 'underline',
			color: styles.purple,
		}
	},
	ownerPassword: siteData.randomstring,
	permissions: {
		printing: 'highResolution',
		modifying: false,
		copying: false,
		annotating: true,
		fillingForms: true,
		contentAccessibility: true,
		documentAssembly: true
	},
	info: {
		title: docfilename,
		author: siteData.docauthor,
		subject: siteData.docfilename
		  }
};

$('#download-resume-pdf').click(function () {
	pdfMake.createPdf(dd).open();
});