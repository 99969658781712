// Bootstrap JS and CSS

import './../css/main.scss';

// Bootstrap Popover
import "./vendor/popover";

// Bootstrap Tooltip
import "./vendor/tooltip";

// Font Awesome
import '@fortawesome/fontawesome-free/js/all.js';

// qrcode
import './vendor/qrcode';

// pdfmake
import './vendor/pdfmake'

