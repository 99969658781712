---
- name: Drupal
  category: Technical
  areas: Content Management Systems (CMS)
- name: Jenkins
  category: Technical
  areas: Continuous integration/continuous delivery (CI/CD)
- name: PowerApps
  category: Technical
  areas: Web Development
- name: PowerAutomate
  category: Technical
  areas: Robotic Process Automation (RPA)
- name: SharePoint
  category: Technical
  areas: Web Development
- name: Windows Server OS
  category: Technical
  areas: System Administration
- name: Agile
  category: Leadership/Strategy
  areas: Project Management
- name: Windows OS
  category: Technical
  areas: Enterprise Desktop Administration
- name: Mac OS
  category: Technical
  areas: Enterprise Desktop Administration
- name: PowerShell
  category: Technical
  areas: Scripting
- name: Technical Staff Management
  category: Leadership/Strategy
  areas: Operations Management
- name: Customer Service
  category: Leadership/Strategy
  areas: Operations Management
- name: Customer Management
  category: Leadership/Strategy
  areas: Operations Management
- name: SAP
  category: Technical
  areas: Enterprise Resource Planning (ERP)
- name: Microsoft Dynamics
  category: Technical
  areas: Enterprise Resource Planning (ERP)
- name: Jekyll
  category: Technical
  areas: Web Development
- name: Unix Shell
  category: Technical
  areas: Scripting
- name: GitLab CI/CD
  category: Technical
  areas: Continuous integration/continuous delivery (CI/CD)
- name: GitHub Actions
  category: Technical
  areas: Continuous integration/continuous delivery (CI/CD)
- name: Docker
  category: Technical
  areas: Web Development
- name: Bootstrap
  category: Technical
  areas: Web Development
- name: Policy Development
  category: Leadership/Strategy
  areas: Communication Skills
- name: Technical Presentation
  category: Leadership/Strategy
  areas: Communication Skills
- name: Trainings
  category: Leadership/Strategy
  areas: Communication Skills
- name: Amazon EC2
  category: Technical
  areas: Cloud Infrastructure
- name: Google Cloud Platform
  category: Technical
  areas: Cloud Infrastructure
- name: HTML
  category: Technical
  areas: Web Development
- name: CSS
  category: Technical
  areas: Web Development
- name: SASS
  category: Technical
  areas: Web Development
- name: JavaScript
  category: Technical
  areas: Web Development
- name: API
  category: Technical
  areas: Web Development
- name: Documentation Management
  category: Leadership/Strategy
  areas: Communication Skills
- name: 'Japanese: Conversational Fluency'
  category: Miscellaneous
  areas: Languages
- name: Asana
  category: Technical
  areas: Collaboration / Work Management
- name: Adobe Workfront
  category: Technical
  areas: Collaboration / Work Management
- name: Smartsheet
  category: Technical
  areas: Collaboration / Work Management
- name: Python
  category: Technical
  areas: Scripting
- name: Git
  category: Technical
  areas: Scripting
- name: PowerQuery
  category: Technical
  areas: Other