import styles from '../../css/base/_bootstrap_overrides.module.scss'


var QRCode = require('qrcode')

var opts  = {
    color: {
      dark: styles.primary, 
      light: styles.white
      }
}

var opts2 = {
    margin: 1,
    width: 50,
    color: {
        dark: styles.white, 
        light: styles.primary
    }
}

document.querySelectorAll('canvas[data-class="qr"]').forEach(canvas => {
    const qrId = canvas.getAttribute('data-qr-id');
    const qrText = canvas.getAttribute('data-qr-text');
    const options = qrId === 'siteQR' ? opts2 : opts;
    QRCode.toCanvas(canvas, qrText, options, function (error) {
      if (error) console.error(error)
      console.log('success!');
    });
  });
  